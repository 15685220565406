@import url('https://fonts.googleapis.com/css2?family=IM+Fell+English&family=Merriweather:wght@300&family=Raleway:wght@300&display=swap');

* {
  box-sizing: border-box;
  margin: 0 auto;
}
#root {
  background-color: #18191a;
}
.appContainer {
  max-width: 1100px;
  box-sizing: border-box;
  margin: 0 auto;
}

.navbar{
  height: 100px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  cursor: pointer;
}
.nav-content{
  padding-top: 20px;
  padding-bottom: 10px;
}
.nav-margin{
  margin: 35px;
  color: #fcf6f5;
  font-size: 30px;
}
.nav-margin:hover{
  text-shadow: 8px 8px 8px  #1F51FF;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
  padding-bottom: 150px;
}
.intro-container:first-child{
  margin-right: 50px;
  height: 700px;
  width: 1100px;
  color: #fcf6f5 ;
}
.about-container:last-child{
  height: 700px;
  width: 1100px;
  // margin-left: 50px;
  padding-top: px;
  color: #fcf6f5;
}
.name {
  font-size: 75px;
  font-family: 'IM Fell English', serif;
  font-weight: 700;
  font-style: italic;
  color: #1F51FF;
}
.role {
  font-size: 45px;
  padding-bottom: 30px;
}
.cv-button {
  cursor: pointer;
  text-decoration: none;
  font-size: 25px;
  background-color: #1F51FF;
  color: #fcf6f5;
  border: 3px solid #fcf6f5;
  width: 170px;
  height: 50px;
  box-shadow: 0px 0px 20px #1F51FF;
}
.cv-button:hover {
  background-color: #18191a;
  color: #1F51FF;
  border: 3px solid #1F51FF;
  box-shadow: 0 0 40px #1F51FF;
}
// .about-header {
//   color: #1F51FF;
//   font-family: 'Merriweather', serif;
//   font-weight: 700;
//   font-size: 55px;
//   padding-bottom: 10px;
//   }
.about-text {
  color: #fcf6f5;
  width: 450px;
  font-size: 18px;
  padding-top: 10px;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  padding-bottom: 10px;
}
.about-text-p1 {
  padding-bottom: 10px;
}
.about-text-p2 {
  padding-bottom: 10px;
}


// .skills {
//   padding-top: 45px;
// }
.javaFavoured {
  font-size: 18px;
  text-align: center;
  color: #fcf6f5;
  padding-top: 40px;
}
.skills-icons {
  display: flex;
  flex-wrap: wrap;
  height:100%;
  width:100%;
  padding-bottom: 120px;
  padding-top: 20px;
}
.skills-icons i {
  width: 100px;
  margin: 15px;
  line-height: 75px;
  font-size: 60px;
  padding:40px;
  color: #fcf6f5;
}
.skills-icons i:hover {
  color: #1F51FF;
}

.projects{
  color: #fcf6f5;
  padding: 10px;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}
.container{
  height:100%;
  width:100%;
  display:flex;
  flex-wrap: wrap;
}
.project-container{
  height:auto;
  width: 450px;
  margin-top:50px;
  margin-bottom: 50px;
}
.image-container, img{
  height:350px;
  width: 450px;
}
.text{
  display:flex;
  flex-direction:column;
  padding: 10px;
  justify-content:center;
  text-align:center;
  color: #fcf6f5;
}
.project-headers{
  font-size: 25px;
  font-family: 'Merriweather', serif;
  font-weight: 700;
  font-style: italic;
  padding-top: 10px;
  padding-bottom: 30px;
  color: #1F51FF;
}
.project-text{
  font-size: 18px;
  padding-bottom: 30px;
}
.project-button{
  margin-left: 30px;
}
#project-giticons {
  font-size: 60px;
  margin-right: 30px;
  color: #fcf6f5;
}
.fA{
  font-size: 55px;
  margin-left: 30px ;
  color: #fcf6f5;
  padding-top: 30px;
}
.project-hr{
  width: 360px;
}
.image{
  display: flex;
  flex: 1;
}
.image .screenshot{
  width: 400px;
}
.screenshot {
  border: white 3px solid;
}

.interest-wrap{
  padding: 45px;
  padding-bottom: 65px;
  color: white;
  font-size: 30px;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}
.interests-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 50px;
}
.interest-container{
  padding-bottom: 20px ;
}
.interest-h3 {
  color: #1F51FF;
  font-family: 'Merriweather', serif;
  font-weight: 700;
  font-size: 25px;
  padding-top: 35px;
}
.interest-description {
  width: 900px;
  font-size: 18px;
  padding-top: 20px;
}

.contact{
  color: #fcf6f5;
  padding:50px;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 100;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}
.contact-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text-container{
  padding: 30px;
  color: #fcf6f5;
  position: relative;
  width: 550px;
  height: 350px;
  box-sizing: border-box;
  margin-top: 50px;
  margin-bottom: 50px;
}
.contact-info{
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  margin-top: 70px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.contact-info a {
  padding-bottom: 40px;
}
.contact a {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
a {
  color: #fcf6f5;
}
.contact-devicons{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  color: #fcf6f5;
}
.icon{
  margin-right: 60px;
  margin-left: 60px;
  height: 100px;
  width: 50px;
  color: #fcf6f5;
}
.images {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 300px;
}
i {
  font-size: 78px;
}

.hr1{
  width: 400px;
  height: 3px;
  background-color: #fcf6f5;
  border: #fcf6f5;
}
.headers {
  text-align: center;
  color: #1F51FF;
  font-family: 'Merriweather', serif;
  font-weight: 700;
  font-size: 55px;
  padding-bottom: 10px;
  padding-top: 20px;
}

@media screen and (max-width: 760px) {
  #root {
    background-color: #18191a;
  }
  .appContainer {
    max-width: 480px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .navbar{
    display: none;
  }
  // .nav-content{
  //   padding-top: 20px;
  //   padding-bottom: 10px;
  // }
  // .nav-margin{
  //   margin: 35px;
  //   color: #fcf6f5;
  //   font-size: 30px;
  // }
  // .nav-margin:hover{
  //   text-shadow: 8px 8px 8px  #1F51FF;
  // }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .intro-container:first-child{
    height: 200px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 200px;
    width: 350px;
    color: #fcf6f5 ;
  }
  .about-container:last-child{
    height: 700px;
    width: 350px;
    color: #fcf6f5;
    text-align: center;
  }
  .name {
    font-size: 20px;
    font-family: 'IM Fell English', serif;
    font-weight: 700;
    font-style: italic;
    color: #1F51FF;
  }
  .role {
    font-size: 16px;
    padding-bottom: 30px;
  }
  .cv-button {
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    background-color: #1F51FF;
    color: #fcf6f5;
    border: 3px solid #fcf6f5;
    width: 100px;
    height: 50px;
    box-shadow: 0px 0px 20px #1F51FF;
  }
  .cv-button:hover {
    background-color: #18191a;
    color: #1F51FF;
    border: 3px solid #1F51FF;
    box-shadow: 0 0 40px #1F51FF;
  }
  // .about-header {
  //   color: #1F51FF;
  //   font-family: 'Merriweather', serif;
  //   font-weight: 700;
  //   font-size: 55px;
  //   padding-bottom: 10px;
  //   }
  .about-text {
    color: #fcf6f5;
    width: 350px;
    font-size: 14px;
    padding-top: 10px;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    padding-bottom: 10px;
  }
  .about-text-p1 {
    padding-bottom: 10px;
  }
  .about-text-p2 {
    padding-bottom: 10px;
  }


  .skills {
    padding-top: 45px;
    width: 350px;
  }
  .javaFavoured {
    font-size: 14px;
    text-align: center;
    color: #fcf6f5;
    padding-top: 40px;
  }
  .skills-icons {
    display: flex;
    flex-wrap: wrap;
    height:100%;
    width:100%;
    padding-bottom: 120px;
    padding-top: 20px;
  }
  .skills-icons i {
    width: 100px;
    margin: 30px;
    line-height: 10px;
    font-size: 40px;
    padding:40px;
    color: #fcf6f5;
  }
  .skills-icons i:hover {
    color: #1F51FF;
  }

  .projects{
    color: #fcf6f5;
    padding: 10px;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
  }
  .container{
    height:100%;
    width:100%;
    display:flex;
    flex-wrap: wrap;
  }
  .project-container{
    height:auto;
    width: 300px;
    margin-top:50px;
    margin-bottom: 50px;
  }
  .image-container, img{
    height:200px;
    width: 300px;
  }
  .text{
    display:flex;
    flex-direction:column;
    padding: 10px;
    justify-content:center;
    text-align:center;
    color: #fcf6f5;
  }
  .project-headers{
    font-size: 20px;
    font-family: 'Merriweather', serif;
    font-weight: 700;
    font-style: italic;
    padding-top: 10px;
    padding-bottom: 30px;
    color: #1F51FF;
  }
  .project-text{
    font-size: 14px;
    padding-bottom: 30px;
  }
  .project-button{
    margin-left: 30px;
  }
  #project-giticons {
    font-size: 30px;
    margin-right: 30px;
    color: #fcf6f5;
  }
  .fA{
    font-size: 30px;
    margin-left: 30px ;
    color: #fcf6f5;
    padding-top: 30px;
  }
  .project-hr{
    width: 175px;
  }
  .image{
    display: flex;
    flex: 1;
  }
  .image .screenshot{
    width: 400px;
  }
  .screenshot {
    border: white 3px solid;
  }

  .interest-wrap{
    padding: 10px;
    padding-bottom: 65px;
    color: white;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
  }
  .interests-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 50px;
  }
  .interest-container{
    padding-bottom: 20px ;
  }
  .interest-h3 {
    color: #1F51FF;
    font-family: 'Merriweather', serif;
    font-weight: 700;
    font-size: 20px;
    padding-top: 35px;
  }
  .interest-description {
    width: 350px;
    font-size: 14px;
    padding-top: 20px;
  }

  .contact{
    color: #fcf6f5;
    padding:0px;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    width: 70%;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
  }
  .contact-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text-container{
    padding: 0px;
    color: #fcf6f5;
    position: relative;
    width: 200px;
    height: 350px;
    box-sizing: border-box;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .contact-info{
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    margin-top: 50px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .contact-info a {
    padding-bottom: 40px;
  }
  .contact a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .links{
    align-items: center;
  }
  a {
    color: #fcf6f5;
  }
  .contact-devicons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #fcf6f5;
  }
  .icon{
    height: 100px;
    width: 50px;
    color: #fcf6f5;
  }
  .images {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 300px;
  }
  i {
    font-size: 30px;
  }

  .hr1{
    width: 175px;
    height: 3px;
    background-color: #fcf6f5;
    border: #fcf6f5;
  }
  .headers {
    text-align: center;
    color: #1F51FF;
    font-family: 'Merriweather', serif;
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 20px;
  }
}
